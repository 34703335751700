import React, { useEffect } from 'react'
import 'twin.macro'

import SEO from 'components/shared/seo'
import useWebinar from 'content-queries/mx/webinar'
import fireEvent from 'context/tracking/events'
import { AutomaticPopup } from 'mx/automatic-popup'
import { BottomCTA } from 'mx/bottom-cta'
import { SectionContent } from 'mx/components/section-content'
import { Press } from 'mx/homepage/press'
import { Reviews } from 'mx/homepage/reviews'
import Layout from 'mx/layout'
import { Hero } from 'mx/webinar/hero'

const WebinarPage = () => {
  useEffect(() => {
    fireEvent({
      type: 'page_viewed',
      pageTitle: 'Webinar',
    })
  }, [])

  const { data } = useWebinar()
  const [
    {
      node: {
        sectionModules: [
          hero,
          typicalCabinets,
          designServices,
          customerService,
          onlineApproach,
        ],
      },
    },
  ] = data

  return (
    <Layout>
      <AutomaticPopup />
      <Hero data={hero} />
      <SectionContent data={typicalCabinets} />
      <Press />
      <SectionContent data={designServices} />
      <SectionContent data={customerService} />
      <Reviews />
      <SectionContent data={onlineApproach} />
      <BottomCTA />
    </Layout>
  )
}
export default WebinarPage
export const Head = () => {
  const { data } = useWebinar()
  const [
    {
      node: {
        meta: { description, image, title },
      },
    },
  ] = data
  return (
    <SEO
      description={description}
      path="/webinar"
      title={title}
      image={image}
    />
  )
}
